<ng-container *ngIf="loginState$.loading; else elseTemplate">
    <div class="display-spinner">
        <mat-spinner></mat-spinner>
    </div>
</ng-container>
<ng-template #elseTemplate>
    <div class="container-uoc">
        <mat-card class="login-card mat-elevation-z24">
            <mat-card-header class="header-card">
                <mat-card-title>Cambiar Contraseña</mat-card-title>
            </mat-card-header>
            <div>
            <form [formGroup]="changePasswordForm" (ngSubmit)="change()">
                
                <section class='section-form'>
                    <!-- Password -->
                    <mat-form-field appearance="fill" class="item-form">
                        <mat-label>Nueva Contraseña</mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'" [formControl]="password" >
                        <button type='button' mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Esconder contraseña'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon></button>
                        <mat-hint>La contraseña debe tener entre 8 y 16 caracteres y, al menos: un dígito, una minúscula, una mayúscula.</mat-hint>                        
                        <mat-error *ngIf="password.hasError('required')">Contraseña de usuario requerida</mat-error>
                        <mat-error *ngIf="password.hasError('pattern')">Formato de contraseña no válida</mat-error>                        
                    </mat-form-field>
                </section>
                <br>

                <section class='section-form'>
                    <!-- Repeat Password -->
                    <mat-form-field appearance="fill" class="item-form">
                        <mat-label>Confirmación de contraseña</mat-label>
                        <input matInput [type]="hidecp ? 'password' : 'text'" [formControl]="confirm_password">
                        <button type='button'  mat-icon-button matSuffix (click)="hidecp = !hidecp" [attr.aria-label]="'Esconder contraseña'" [attr.aria-pressed]="hidecp">
                            <mat-icon>{{hidecp ? 'visibility_off' : 'visibility'}}</mat-icon></button>
                        <mat-error *ngIf="confirm_password.hasError('required')">Confirmación de contraseña requerida</mat-error>                        
                    </mat-form-field>
                </section>

                <section class='section-form'>
                    <mat-error class="item-form" *ngIf="loginState$.error && (bSubmitted)"> 
                        <small> {{loginState$.error.message}}</small>
                    </mat-error>
                    <mat-error *ngIf="changePasswordForm.hasError('noEquals') && (confirm_password.dirty && password.dirty)">
                        <small>Las contraseñas no coinciden</small> </mat-error>
                </section>

                <!-- Change Password button -->
                <section class='section-form'>
                    <button mat-raised-button color="primary" type="submit" [disabled]="!changePasswordForm.valid" class="item-form">Cambiar contraseña</button>
                </section>        
                <section class='section-form'>
                    <small>
                <p><a routerLink='/'>Inicio</a> </p> </small></section>        
            </form>
            </div>
        </mat-card>
    </div>
</ng-template>