import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { User } from 'src/app/auth/models/user';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { PrefactibilidadService } from 'src/app/prefactibilidad/service/prefactibilidad.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public user: User;
  fecha: Date;
  monthNames = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  monthsCalculate = [];

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: {};

  estudios: number[];
  proyectos: number[];

  lastProjects:any[];
  lastEstudios:any[];

  constructor(
    private store: Store<AppState>,
    private prefactibilidadService: PrefactibilidadService
  ) {
    this.fecha = new Date();
    this.updateData([], []);
  }

  ngOnInit(): void {
    this.store.select('authApp').subscribe((response) => {
      if (response.userAuth) {
        this.user = Object.assign(new User(), response.userAuth);
      } else {
        this.user = response.userAuth;
      }
    });
    
    if(this.user.isAdministrador()){
      let estadistics$= this.prefactibilidadService.getEstadisticas().subscribe((response) => {        
        this.proyectos = [ ...response.proyectos];
        this.estudios = [ ...response.prefactibilidad];              
        this.updateData(this.proyectos, this.estudios);        
      });          
    }

    if(this.user.isPlanificador()){
      this.prefactibilidadService.getLastWork().subscribe((response)=>{
        this.lastProjects = response.proyectos;
        this.lastEstudios = response.prefactibilidad;
      });
    }
      
  }

  updateData(proyectos, estudios){
    this.chartOptions = {
      chart: {
        type: 'area',
      },
      title: {
        text: 'Historico de proyectos y estudios de prefactibilidad',
      },
      subtitle: {
        text: 'Wetch',
      },
      xAxis: {
        categories: this.getCategories(),
        tickmarkPlacement: 'on',
        title: {
          enabled: false,
        },
      },
      yAxis: {
        title: {
          text: 'Cantidad',
        },
      },
      tooltip: {
        split: true,
        valueSuffix: ' estudios',
      },
      plotOptions: {
        area: {
          stacking: 'normal',
          lineColor: '#666666',
          lineWidth: 1,
          marker: {
            lineWidth: 1,
            lineColor: '#666666',
          },
        },
      },
      series: [
        {
          name: 'Proyectos',
          color: '#269fa4',
          data: proyectos,
        },
        {
          name: 'Estudios Prefactibilidad',
          color: '#c1c1c1',
          data: estudios,
        },
      ],
    };
    
  }

  getCategories(): string[] {
    let months = [];
    let months_calculate = [];
    let actualMonth = this.fecha.getMonth();
    for (var i = 0; i < 6; i++) {
      if (actualMonth === -1) {
        actualMonth = 11;
      }
      months.push(this.monthNames[actualMonth]);
      months_calculate.push(actualMonth);
      actualMonth = actualMonth - 1;
    }
    this.monthsCalculate = months_calculate;
    return months.reverse();
  }
}
