<div class="container-uoc">

    <h1 class="title__page">405</h1>
    <h2>Acceso no autorizado</h2>

    <div fxLayout='column' fxLayoutAlign='center center' class='div-title'>
        <a href="/" style="text-decoration: none;"><p class="title animate__animated animate__backInUp" > [ Wetch ]  </p></a> 
        <small> <em> La solución arquitectónica eficaz para usted</em></small>
    </div> 

</div>