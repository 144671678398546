<mat-toolbar color="primary">  
    <div fxLayout fxLayoutAlign="start center">
      <button fxShow fxHide.gt-xs mat-icon-button aria-label="Menú" (click)="onToggleSidenav()">
        <mat-icon>menu</mat-icon>
      </button>  
      <span>
          <a *ngIf="user" [routerLink]="['dashboard']" class="titulo">Wetch</a>          
          <a *ngIf="!user" [routerLink]="['']" class="titulo">Wetch</a>          
      </span>
    </div>
    <span class="space-menu"></span>
    <div fxHide fxShow.gt-xs>
        <ul fxLayout fxLayoutGap='20px' class="navigation-items"  >      
          <li *ngIf="!user"><a class="item secondary" routerLink='/quienes-somos'>Empresa</a></li>          
          <li *ngIf="!user"><a class="item secondary" routerLink='/contacto'>Contáctanos</a></li>      
          <!-- <li *ngIf="!user" ><a class="item hover_rouded secondary" [routerLink]="['register']">Regístrate</a></li>       -->
          <li *ngIf="!user" class="rounded"><a class="item" [routerLink]="['login']">Iniciar Sesión</a></li>      
          <li *ngIf="user && user.isPlanificador()"><a class="item" routerLink='/proyectos/proyectos-list'> Mis Proyectos</a></li>                
        </ul>       
    </div>  

    
    <!-- <button mat-menu-item [routerLink]="['register']">Regístrate</button> -->

    <div *ngIf="user">      
      <button class="item"  mat-button [matMenuTriggerFor]="menu"><mat-icon>account_circle</mat-icon>{{user.name}}</button>
      <mat-menu #menu="matMenu" xPosition="before">        
          <button mat-menu-item (click)="profile()">Mi perfil</button>            
          <button mat-menu-item (click)="logOut()">Cerrar Sesión</button>        
      </mat-menu>       
    </div>
</mat-toolbar>