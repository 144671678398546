<ng-container *ngIf="loginState$.loading; else elseTemplate">
    <div class="display-spinner">
        <mat-spinner></mat-spinner>
    </div>
</ng-container>
<ng-template #elseTemplate>
    <div class="container-uoc" [@fadeInOut]>
        <mat-card class="login-card mat-elevation-z24">
            <mat-card-header class="header-card">
                <mat-card-title>Iniciar Sesión</mat-card-title>
            </mat-card-header>
            <div>
            <form [formGroup]="loginForm" (ngSubmit)="logIn()">
                <section class='section-form'>
                    <!-- Email -->
                    <mat-form-field appearance="fill" class="item-form">
                        <mat-label>Correo electrónico</mat-label>
                        <input matInput type="email" [formControl]="email" placeholder="Correo electrónico">
                        <mat-error *ngIf="email.hasError('required')">Email es requerido</mat-error>
                        <mat-error *ngIf="email.hasError('email')">Email no tiene el formato correcto</mat-error>
                    </mat-form-field>
                </section>

                <section class='section-form'>
                    <!-- Password -->
                    <mat-form-field appearance="fill" class="item-form">
                        <mat-label>Contraseña</mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'" matInput type="password" [formControl]="password" placeholder="Contraseña de usuario">
                        <button type='button' mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Esconder contraseña'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon></button>
                        <mat-error *ngIf="password.hasError('required')">Contraseña de usuario requerida</mat-error>
                    </mat-form-field>
                </section>
                <section class='section-form error-notifications'>
                    <mat-error class="item-form" *ngIf="loginState$.error  && (bSubmitted)">
                        <small>
                            {{loginState$.error.message}}
                        </small>
                    </mat-error>
                    <small><strong> <a class="link-black" href="/reset-password">¿Olvidaste tu contraseña?</a> </strong></small>
                </section >
                <!-- Sign in button -->
                <section class='section-form'>
                    <button mat-raised-button color="primary" type="submit" [disabled]="!loginForm.valid" class="item-form">Ingresar</button>
                </section>                

                <section class='section-form'>
                    <small>
                <p>¿No tienes credenciales de acceso? <a routerLink='/register'>Crear cuenta</a> </p> </small></section>
            </form>
            
            </div>
        </mat-card>
    </div>
</ng-template>