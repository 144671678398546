<ng-container *ngIf="loginState$.loading; else elseTemplate">
    <div class="display-spinner">
        <mat-spinner></mat-spinner>
    </div>
</ng-container>
<ng-template #elseTemplate>
    <div class="container-uoc">
        <mat-card class="login-card mat-elevation-z24">
            <mat-card-header class="header-card">
                <mat-card-title>Reestablecer contraseña</mat-card-title>
            </mat-card-header>
            <div>
            <form [formGroup]="resetPasswordForm" (ngSubmit)="reset()">
                <section class='section-form'>
                    <!-- Email -->
                    <mat-form-field appearance="fill" class="item-form">
                        <mat-label>Correo electrónico</mat-label>
                        <input matInput type="email" [formControl]="email">
                        <mat-error *ngIf="email.hasError('required')">Campo requerido</mat-error>
                        <mat-error *ngIf="email.hasError('email')">El correo electrónico no tiene el formato correcto</mat-error>
                        <mat-error *ngIf="email.hasError('userNoExist')">Este email no encontrado</mat-error>
                    </mat-form-field>
                </section>
                <section class='section-form error-notifications'>
                    <mat-error class="item-form" *ngIf="loginState$.error  && (bSubmitted)">
                        <small>                            
                            {{loginState$.error.message.message}}
                        </small>
                    </mat-error>
                </section>

                <!-- Sign in button -->
                <section class='section-form'>
                    <button mat-raised-button color="primary" type="submit" [disabled]="!resetPasswordForm.valid" class="item-form">Resetear contraseña</button>
                </section>                                
            </form>
            
            </div>
        </mat-card>
    </div>
</ng-template>