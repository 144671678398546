<form [formGroup]="activarForm" (ngSubmit)="active()" >
    <h3 mat-dialog-title>Activar Usuario</h3>
    <div mat-dialog-content fxLayout="column">
        <p class="margin section-form">Al activar al usuario, este tendrá acceso al sistema en su próximo inicio de sesión.</p>
        <p class="margin section-form">¿Está seguro de activar al usuario <strong> <em> {{userSystem.name}} </em></strong>?</p>        
        <section class='section-form' fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%" fxFlex.xl="100%" fxFlex.lg="100%">                                    
            <mat-form-field appearance="outline" class="item-form">
                <input matInput [formControl]="activar" placeholder="activar">
                <mat-hint>Escriba la palabra 'activar' para continuar </mat-hint>
                <mat-error *ngIf="activar.hasError('required')">Este campo es requerido</mat-error>
            </mat-form-field>
        </section>
    </div>
    <div mat-dialog-actions class="btn-right">
        <button mat-button mat-dialog-close>Cerrar</button>
        <button mat-raised-button color="primary" type="submit" [disabled]="!activarForm.valid">Activar</button>
    </div>
</form>